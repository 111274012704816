export default {
  "Average Snowfall": "Average total snowfall in inches",
  "Hottest": "Highest observed temperature in the dataset",
  "Coldest": "Lowest observed temperature in the dataset",
  "Max Wind": "Maximum wind speed in miles per hour observed in the dataset",
  "# Days < 32°": "Typical number of days the minimum temperature is less than 32°F",
  "# Days > 90°": "Typical number of days the maximum temperature is more than 90°F",
  "Normal Average": "Typical mean temperature",
  "Normal Cooling Degree Days": "The sum of the number of degrees the temperature is above 65°F. Use this as a rough way to compare energy costs for cooling a building",
  "Normal Heating Degree Days": "The sum of the number of degrees the temperature is below 65°F. Use this as a rough way to compare energy costs for heating a building",
  "Normal Hottest": "Typical high of the day",
  "Normal Coldest": "Typical low of the day",
  "Normal Precipitation": "Typical total preciptation in inches",
  "Max Possible Sunshine": "Total time that sunshine reaches the surface of Earth is expressed as the percentage of the maximum amount possible from sunrise to sunset with clear sky conditions",
  '# Days > 0.01" of Rain': "Typical number of days where there is at least 0.01 inches of precipitation",
  "Relative Humidity": "Typical percentage measure of the amount of moisture in the air compared to the maximum amount of moisture the air can hold at the same temperature and pressure",
  "Average Wind": "Typical wind speed in miles per hour",
};
